import * as constants from 'consts';
import isString from 'lodash/isString';
import get from 'lodash/get';

const utilsApp = {
  isProduction: () => {
    return !['localhost', 'edgedev1.azurewebsites.net', 'edgedev2.azurewebsites.net'].includes(window.location.hostname);
  },
  isEdgeProduction: () => {
    return ['edge.priceforbes.com', 'edge.ardonaghspecialty.com', 'edge.bishopsgateinsurance.co.uk'].includes(window.location.hostname);
  },
  isEdgeUAT: () => {
    return ['edgeuat.azurewebsites.net'].includes(window.location.hostname);
  },
  isDevelopment: (vars) => {
    return vars && vars.type === 'development';
  },
  isLocalhost: () =>
    Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    ),
  getConfig: async () => {
    const result = await fetch('/config/config.json', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const config = await result.json();
    return config;
  },

  getCurrentYear: () => {
    return new Date().getFullYear();
  },

  getBrandName: (brand) => {
    if (!brand || !isString(brand)) return '';

    const defaultName = constants[`BRAND_PRICEFORBES_NAME`] || '';

    return constants[`BRAND_${brand.toUpperCase()}_NAME`] || defaultName;
  },

  getBrandNameAndSourceSystemName: (sourceSystemId, brands) => {
    const brand = brands?.find((brand) => brand.sourceSystems.find((sourceSystem) => sourceSystem.id === sourceSystemId)) || {};
    const sourceSystemName = brand?.sourceSystems?.find((sourceSystem) => sourceSystem.id === sourceSystemId)?.name || '';

    return {
      brandAndSourceSystemName: `${brand.name} - ${sourceSystemName}`,
      brandName: brand.name,
      sourceSystemName,
    };
  },

  getAppName: () => {
    return constants[`APP_NAME`];
  },

  getHostName: () => {
    return (window && get(window, 'location.hostname')) || '';
  },

  getElement: (id) => {
    if (!id || !isString(id)) return null;

    return document.body.querySelector(id);
  },
  hotjar: (id, sv) => {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: id, hjsv: sv };
      h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      if (!document.querySelector('script[src*="' + h._scriptPath + '"]')) {
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = h._scriptPath;
        a.appendChild(r);
      }
    })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
  },
};

export default utilsApp;
