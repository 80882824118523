// MUI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Edge
import { useQuoteBindStyles } from 'modules/QuoteBind';
import { EndorsementQuote } from './EndorsementQuote';
import { FormGrid } from 'components';

export const EndorsementQuotes = ({ title, endorsements, riskType, policyCurrency, canCurrentUserBind, canCurrentUserDismissIssues }) => {
  const classes = useQuoteBindStyles();

  return (
    <Box className={classes.root} data-testid="EndorsementQuotes">
      <Box className={classes.header} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="start">
          <Typography variant="h3" style={{ marginBottom: 0, marginRight: 10, fontWeight: 600 }}>
            {title}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.content}>
        <FormGrid container>
          {endorsements.map((endorsement) => (
            <FormGrid item xs={12} sm={6} md={4} lg={3} key={endorsement.id}>
              <EndorsementQuote
                quote={endorsement}
                policyCurrency={policyCurrency}
                riskType={riskType}
                canCurrentUserBind={canCurrentUserBind}
                canCurrentUserDismissIssues={canCurrentUserDismissIssues}
              />
            </FormGrid>
          ))}
        </FormGrid>
      </Box>
    </Box>
  );
};
