import get from 'lodash/get';
import * as utils from 'utils';
import { firstBy } from 'thenby';

const initialState = {
  brands: [],
  businessTypes: [],
  capacityTypes: [],
  clients: [],
  countries: [],
  currencies: [],
  departments: [],
  insureds: [],
  markets: [],
  offices: [],
  statuses: {
    account: [],
    placement: [],
    policy: [],
    policyMarketQuote: [],
  },
  sourceSystems: [],
  underwriters: [],
  loaded: false,
};

const referenceDataReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'REFERENCE_DATA_GET_SUCCESS':
      return {
        ...state,
        brands: action.payload.brands,
        businessTypes: [],
        capacityTypes: action.payload.capacityTypes || [],
        currencies: action.payload.currencies,
        countries: utils.generic.isValidArray(action.payload.countries)
          ? action.payload.countries.filter((c) => c.codeAlpha2 && c.codeAlpha3 && c.name)
          : [],
        departments: action.payload.departments,
        statuses: {
          placement: action.payload.placementStatuses,
          policy: action.payload.policyStatuses,
          policyMarketQuote: action.payload.policyMarketQuoteStatuses,
          account: action.payload.accountStatuses,
        },
        newRenewalBusinesses: action.payload.newRenewalBusinesses,
        rationales: action.payload.placementMarketRationales,
        declinatures: action.payload.placementMarketDeclinatures,
        sourceSystems: action.payload.sourceSystems,
        loaded: true,
      };

    case 'REFERENCE_DATA_FILTER_BUSINESSTYPES_BY_DEPTID':
      let parsedBusinessTypes = [];
      const departments = get(state, `departments`, {});

      const currentDepartment = departments.find((dept) => {
        return dept.id === action.payload;
      });

      if (currentDepartment && get(currentDepartment, 'businessTypes', []).length > 0) {
        parsedBusinessTypes = currentDepartment.businessTypes.sort(
          firstBy(utils.sort.array('numeric', 'sequenceNumber')).thenBy(utils.sort.array('lexical', 'description'))
        );
      }

      return {
        ...state,
        businessTypes: parsedBusinessTypes,
      };

    case 'REFERENCE_DATA_GET_BY_TYPE_MARKETS_SUCCESS':
      return {
        ...state,
        markets: action.payload,
        underwriters: [],
      };

    case 'REFERENCE_DATA_GET_BY_TYPE_OFFICES_SUCCESS':
      return {
        ...state,
        offices: utils.office.withFullName(action.payload),
      };

    case 'REFERENCE_DATA_GET_BY_TYPE_CLIENTS_SUCCESS':
      return {
        ...state,
        clients: action.payload,
      };

    case 'REFERENCE_DATA_GET_BY_TYPE_INSUREDS_SUCCESS':
      return {
        ...state,
        insureds: action.payload,
      };

    case 'REFERENCE_DATA_FILTER_UNDERWRITERS_BY_MARKET':
      return {
        ...state,
        underwriters: [...get(action, 'payload.underwriters', [])],
      };

    case 'REFERENCE_DATA_RESET_BUSINESS_TYPES':
      return {
        ...state,
        businessTypes: [],
      };

    case 'REFERENCE_DATA_RESET_MARKETS':
      return {
        ...state,
        markets: [],
      };

    case 'REFERENCE_DATA_RESET_UNDERWRITERS':
      return {
        ...state,
        underwriters: [],
      };

    case 'REFERENCE_DATA_RESET_INSUREDS':
      return {
        ...state,
        insureds: [],
      };

    default:
      return state;
  }
};

export default referenceDataReducers;
