import React from 'react';
import PropTypes from 'prop-types';

// app
import { useFieldMappingStyles } from './FieldMapping.styles';
import { FormGrid, Accordion } from 'components';

// mui
import { Chip, Divider, Button } from '@material-ui/core';
import { FieldItem } from './FieldItem';
import { useMedia } from 'hooks';
import { UploadBDXActions, UploadBDXContent, HeaderSelect } from '../components';
import { FUZZY, NO_MATCH, PREVIOUS, REAL } from 'consts';
import * as utils from 'utils';

FieldMappingView.propTypes = {
  fields: PropTypes.array.isRequired,
  isTablet: PropTypes.bool.isRequired,
};

export function FieldMappingView({ methods, fields, expanded, handleAccordion, handlers, isPopupOpen, inputEl, rowId, sheetName }) {
  const classes = useFieldMappingStyles();
  const isTablet = useMedia().tablet;

  const { fuzzyFields, reqFields, realFields, previousFields, matchedFields } = fields;

  return (
    <UploadBDXContent
      actions={
        <UploadBDXActions handleBack={handlers.handleBackClick} handleNext={handlers.handleNext} labelNext={utils.string.t('app.next')}>
          <>
            <Button
              ref={inputEl}
              onClick={() => {
                handlers.setIsPopupOpen(true);
              }}
              variant="contained"
              color="secondary"
            >
              {utils.string.t('form.bdx.selectRow')}
            </Button>
            {isPopupOpen && (
              <HeaderSelect
                isRowOpen={isPopupOpen}
                defaultRow={rowId + 1}
                handleClose={() => handlers.setIsPopupOpen(false)}
                reUpload={handlers.reUpload}
                inputEl={inputEl}
              />
            )}
          </>
        </UploadBDXActions>
      }
    >
      <div className={classes.root} data-testid="fieldmapping-grid">
        <h2 className={classes.sheetName}>
          {utils.string.t('form.bdx.sheet')}: {sheetName}
        </h2>
        {previousFields.length > 0 && (
          <Accordion
            icon={true}
            secondaryIcon={
              <Chip
                size="small"
                style={{ backgroundColor: '#2CC6AB', color: '#FFFFFF', marginLeft: '5px' }}
                label={previousFields.length}
              />
            }
            expanded={expanded.includes(PREVIOUS)}
            title={{ text: 'Previously Matched', type: 'h2' }}
            style={{ margin: '10px' }}
            onChange={handleAccordion(PREVIOUS)}
            testid="previous"
          >
            <FormGrid container spacing={isTablet ? 2 : 3} justifyContent="flex-start" data-testid="field-mapping">
              {previousFields.map((field, index) => (
                <FieldItem
                  key={field.name}
                  item={previousFields[index]}
                  list={previousFields}
                  matchedFields={matchedFields}
                  methods={methods}
                  classes={classes}
                />
              ))}
            </FormGrid>
          </Accordion>
        )}
        {previousFields.length > 0 && (realFields.length > 0 || reqFields.length > 0) && <Divider />} {/* ? */}
        {realFields.length > 0 && (
          <Accordion
            icon={true}
            secondaryIcon={
              <Chip size="small" style={{ backgroundColor: '#2CC6AB', color: '#FFFFFF', marginLeft: '5px' }} label={realFields.length} />
            }
            expanded={expanded.includes(REAL)}
            title={{ text: 'Fully Matched', type: 'h2' }}
            style={{ margin: '10px' }}
            onChange={handleAccordion(REAL)}
            testid="real"
          >
            <FormGrid container spacing={isTablet ? 2 : 3} justifyContent="flex-start" data-testid="field-mapping">
              {realFields.map((field, index) => (
                <FieldItem
                  key={field.name}
                  item={realFields[index]}
                  list={realFields}
                  matchedFields={matchedFields}
                  methods={methods}
                  classes={classes}
                />
              ))}
            </FormGrid>
          </Accordion>
        )}
        {realFields.length > 0 && (fuzzyFields.length > 0 || reqFields.length > 0) && <Divider />}
        {fuzzyFields.length > 0 && (
          <Accordion
            icon={true}
            secondaryIcon={
              <Chip size="small" style={{ backgroundColor: '#edac00', color: '#FFFFFF', marginLeft: '5px' }} label={fuzzyFields.length} />
            }
            expanded={expanded.includes(FUZZY)}
            title={{ text: 'Partially Matched', type: 'h2' }}
            style={{ margin: '10px' }}
            onChange={handleAccordion(FUZZY)}
            testid="fuzzy"
          >
            <FormGrid container spacing={isTablet ? 2 : 3} justifyContent="flex-start" data-testid="field-mapping">
              {fuzzyFields.map((field, index) => (
                <FieldItem
                  key={field.name}
                  item={fuzzyFields[index]}
                  list={fuzzyFields}
                  matchedFields={matchedFields}
                  methods={methods}
                  classes={classes}
                />
              ))}
            </FormGrid>
          </Accordion>
        )}
        {fuzzyFields.length > 0 && reqFields.length > 0 && <Divider />}
        {reqFields.length > 0 && (
          <Accordion
            icon={true}
            secondaryIcon={<Chip size="small" style={{ marginLeft: '5px' }} label={reqFields.length} />}
            expanded={expanded.includes(NO_MATCH)}
            title={{ text: 'Unmatched', type: 'h2' }}
            onChange={handleAccordion(NO_MATCH)}
            testid="nomatch"
          >
            <FormGrid container spacing={isTablet ? 2 : 3} justifyContent="flex-start" data-testid="field-mapping">
              {reqFields.map((field, index) => (
                <FieldItem
                  key={field.name}
                  item={reqFields[index]}
                  list={reqFields}
                  matchedFields={matchedFields}
                  methods={methods}
                  classes={classes}
                />
              ))}
            </FormGrid>
          </Accordion>
        )}
      </div>
    </UploadBDXContent>
  );
}
