import { Fragment } from 'react';

import { Box, Button, Divider, Drawer, IconButton, makeStyles, Typography } from '@material-ui/core';
// mui
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Badge, CustomAutocompleteMui, FilterDateRange } from 'components';

import * as utils from 'utils';

import { styles } from './FilterWithDrawer.styles';

export default function FilterWithDrawerView({
  size,
  page,
  title,
  filterIsOpen,
  numberOfFilters,
  filterOptions,
  selectedFilters,
  toggleFilterOpen,
  handleValueChange,
  handleResetFilter,
  disabled,
}) {
  const classes = makeStyles(styles, { name: 'FilterDrawer' })();

  const handleReset = () => {
    handleResetFilter();
  };
  const BadgeIcons = ({ badgeContent, type = 'primary' }) => (
    <Badge badgeContent={badgeContent} compact type={type}>
      <FilterListIcon />
    </Badge>
  );

  return (
    <>
      <IconButton
        data-testid="button-filter-with-drawer"
        disableRipple
        size={size}
        color="inherit"
        onClick={toggleFilterOpen}
        disabled={disabled}
      >
        <BadgeIcons badgeContent={numberOfFilters} />
      </IconButton>
      {numberOfFilters > 0 ? (
        <IconButton
          data-testid="button-filter-with-drawer"
          disableRipple
          size={size}
          color="inherit"
          onClick={handleResetFilter}
          style={{
            marginLeft: 4,
          }}
        >
          <BadgeIcons badgeContent="x" type="error" />
        </IconButton>
      ) : null}

      <Drawer
        anchor="right"
        style={{
          width: '400px',
        }}
        open={filterIsOpen}
        onClose={toggleFilterOpen}
        data-testid="drawer-filter-with-sidebar"
        BackdropProps={{ invisible: true }}
      >
        <Box
          position="relative"
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          classes={{ root: classes.filterTop }}
        >
          <Typography variant="subtitle1" classes={{ root: classes.filterTitle }}>
            {title || utils.string.capitalise(page)}
          </Typography>
          <IconButton data-testid="close-button-filter-sidebar" onClick={toggleFilterOpen}>
            <CloseIcon width={20} height={20} />
          </IconButton>
        </Box>
        <Divider />
        <Box classes={{ root: classes.filterContainer }}>
          {filterOptions?.map((filterOption, index) => (
            <Fragment key={filterOption.key}>
              {filterOption.type === 'autocomplete' && filterOption.options?.length > 0 && (
                <>
                  <Box sx={{ px: 2, py: 2 }} key={filterOption.key} position="relative">
                    <CustomAutocompleteMui
                      key={filterOption.key}
                      title={filterOption.title}
                      optionsSearchText={filterOption.title}
                      handleValueChange={handleValueChange}
                      selectionKey={`${filterOption?.key}`}
                      selectedOptions={selectedFilters ? selectedFilters[filterOption?.key] || [] : []}
                      options={filterOption.options || []}
                      groupBy={filterOption?.groupBy ? filterOption.groupBy : null}
                      optionKey="value"
                      optionLabel="name"
                    />
                  </Box>
                  <Divider />
                </>
              )}

              {filterOption.type === 'Date' && (
                <>
                  <Box sx={{ px: 2, py: 2 }} position="relative">
                    <FilterDateRange
                      title={filterOption.title}
                      handleOnChange={handleValueChange}
                      selectionKey={`${filterOption?.key}`}
                      selection={selectedFilters ? selectedFilters[filterOption?.key] : null}
                    />
                  </Box>
                  <Divider />
                </>
              )}
            </Fragment>
          ))}
        </Box>

        <Box
          position="absolute"
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="flex-end"
          classes={{ root: classes.filterFooter }}
        >
          <Button size="medium" color="default" variant="outlined" onClick={handleReset}>
            {utils.string.t('filter.clearAll')}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
