import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import Alert from '@material-ui/lab/Alert';

import { Button, ErrorBoundary, Header, Loader, Modal, Nav, Notification } from 'components';

import { COVERHOLDERS, MARKETS } from 'consts';
import config from 'config';
import * as utils from 'utils';

import styles from './App.styles';

// dynamic async imports
const Admin = lazy(() => import('pages/Admin/Admin'));
const AdminUser = lazy(() => import('pages/Admin/User'));
const OperationalClients = lazy(() => import('pages/Admin/Clients/OperationalClients'));
const OperationalClientDetails = lazy(() => import('pages/Admin/Clients/OperationalClientDetails'));
const OperationalMarkets = lazy(() => import('pages/Admin/Markets/OperationalMarkets'));
const OperationalMarketDetails = lazy(() => import('pages/Admin/Markets/OperationalMarketDetails'));
const OperationalThirdParty = lazy(() => import('pages/Admin/ThirdParties/OperationalThirdParty'));
const OperationalThirdPartyDetails = lazy(() => import('pages/Admin/ThirdParties/OperationalThirdPartyDetails'));
const ImportUsers = lazy(() => import('pages/Admin/ImportUsers/ImportUsers'));

const Blank = lazy(() => import('pages/Blank/Blank'));
const Client = lazy(() => import('pages/Client/Client'));
const ClaimsTaskManagement = lazy(() => import('pages/ClaimsTaskManagement/ClaimsTaskManagement'));
const Department = lazy(() => import('pages/Department/Department'));
const Home = lazy(() => import('pages/Home/Home'));
const Icons = lazy(() => import('pages/Icons/Icons'));
const IndustryNews = lazy(() => import('pages/IndustryNews/IndustryNews'));
const Market = lazy(() => import('pages/Market/Market'));
const Modelling = lazy(() => import('pages/Modelling/Modelling'));
const Placement = lazy(() => import('pages/Placement/Placement'));
const QuoteBind = lazy(() => import('pages/QuoteBind/QuoteBind'));
const RiskDetails = lazy(() => import('pages/RiskDetails/RiskDetails'));
const QuoteBindAdmin = lazy(() => import('pages/QuoteBindAdmin/QuoteBindAdmin'));
const QuoteBindFacilityDetails = lazy(() => import('pages/QuoteBindFacilityDetails'));
const QuoteBindAggregate = lazy(() => import('pages/QuoteBindAggregate/QuoteBindAggregate'));
const Binders = lazy(() => import('pages/Binders/Binders'));
const BinderDetails = lazy(() => import('pages/BinderDetails/BinderDetails'));
const FacilityDetails = lazy(() => import('pages/FacilityDetails/FacilityDetails'));
const BordereauxDetails = lazy(() => import('pages/BordereauxDetails'));
const LineageDetails = lazy(() => import('pages/LineageDetails/LineageDetails'));
const Policy = lazy(() => import('pages/Policy/Policy'));
const Opportunity = lazy(() => import('pages/Opportunity/Opportunity'));
const OpeningMemo = lazy(() => import('pages/OpeningMemo/OpeningMemo'));
const Trips = lazy(() => import('pages/Trips/Trips'));
const Reporting = lazy(() => import('pages/Reporting/Reporting'));
const ReportingGroup = lazy(() => import('modules/ReportingGroup/ReportingGroup'));
const ReportingDetails = lazy(() => import('modules/ReportingDetails/ReportingDetails'));

export const AppView = ({ user, isDev, isProduction, hasLoader, sw }) => {
  const classes = makeStyles(styles, { name: 'App' })();

  // Roles
  const isBroker = utils.user.isBroker(user);
  const isUnderwriter = utils.user.isUnderwriter(user);
  const isAdmin = utils.user.isAdmin(user);
  const hasAccessToQB = utils.user.hasAccessToQB(user);
  const isProducer = utils.user.isProducer(user);
  const isClaimsTechnician = utils.user.isClaimsTechnician(user);

  return (
    <div id="root" className={classes.root} data-testid="root">
      <Header />

      <div id="body" className={classes.body} data-testid="body">
        <Nav />

        <div id="content" className={classes.content} data-testid="content">
          <ErrorBoundary>
            <Suspense fallback={<Loader visible />}>
              <Switch>
                {/* Dev routes */}
                {isDev && <Route exact path="/blank" component={Blank} />}
                {isDev && <Route exact path="/icons" component={Icons} />}
                {/* Admin routes */}
                {isAdmin && <Route exact path={`${config.routes.admin.root}`} component={Admin} />}
                {isAdmin && <Route exact path={`${config.routes.admin.userDetails}/:id`} component={AdminUser} />}
                {isAdmin && <Route exact path={`${config.routes.admin.operationalClients}`} component={OperationalClients} />}
                {isAdmin && <Route exact path={`${config.routes.admin.operationalClients}/:id`} component={OperationalClientDetails} />}
                {isAdmin && <Route exact path={`${config.routes.admin.operationalMarkets}`} component={OperationalMarkets} />}
                {isAdmin && <Route exact path={`${config.routes.admin.operationalMarkets}/:id`} component={OperationalMarketDetails} />}
                {isAdmin && <Route exact path={`${config.routes.admin.operationalThirdParty}`} component={OperationalThirdParty} />}
                {isAdmin && (
                  <Route exact path={`${config.routes.admin.operationalThirdParty}/:id`} component={OperationalThirdPartyDetails} />
                )}
                {isAdmin && <Route exact path={`${config.routes.admin.importUsers}`} component={ImportUsers} />}

                {/* Broker routes */}
                {isBroker && <Route exact path={`${config.routes.checklist.root}/:openingMemoId?`} component={OpeningMemo} />}
                {isBroker && <Route exact path={`${config.routes.modelling.root}/:modellingId?`} component={Modelling} />}
                {isBroker && <Route exact path={`${config.routes.opportunity.root}/:id?`} component={Opportunity} />}
                {/* Binder Management routes */}
                {isBroker && <Route exact path={`${config.routes.binders.root}`} component={Binders} />}
                {isUnderwriter && <Route exact path={`${config.routes.binders.root}`} component={Binders} />}
                {(isProducer || isBroker) && (
                  <Route
                    exact
                    path={[config.routes.binders.coverholders, config.routes.binders.root]}
                    render={(props) => <Binders {...props} view="COVERHOLDERS" />}
                  />
                )}
                {(isUnderwriter || isBroker) && (
                  <Route
                    exact
                    path={[config.routes.binders.markets, config.routes.binders.root]}
                    render={(props) => <Binders {...props} view="MARKETS" />}
                  />
                )}
                {isBroker && (
                  <Route
                    exact
                    path={[config.routes.binders.report, config.routes.binders.root]}
                    render={(props) => <Binders {...props} view="OVERVIEW_REPORT" />}
                  />
                )}
                {isBroker && (
                  <Route
                    exact
                    path={[config.routes.binders.lineage, config.routes.binders.root]}
                    render={(props) => <Binders {...props} view="LINEAGE" />}
                  />
                )}
                {(isUnderwriter || isBroker) && (
                  <Route exact path={`${config.routes.binders.market}/:marketId`}>
                    <BinderDetails type={MARKETS} />
                  </Route>
                )}
                {(isProducer || isBroker) && (
                  <Route exact path={`${config.routes.binders.coverholder}/:coverholderId`}>
                    <BinderDetails type={COVERHOLDERS} />
                  </Route>
                )}
                {(isProducer || isBroker || isUnderwriter) && (
                  <Route
                    exact
                    path={`${config.routes.binders.root}/:binderType/:binderId/${config.routes.binders.facilityDetails}/:facilityUMR`}
                    component={FacilityDetails}
                  />
                )}
                {(isProducer || isBroker || isUnderwriter) && (
                  <Route
                    path={`${config.routes.binders.root}/:binderType/:binderId/${config.routes.binders.facilityDetails}/:facilityUMR/:bdxType/:yearMonthSubmission`}
                    component={BordereauxDetails}
                  />
                )}
                {isBroker && <Route path={`${config.routes.binders.lineage}/:yearMonthSubmission`} component={LineageDetails} />}

                {/* Quote & Bind routes */}
                {hasAccessToQB && <Route exact path={`${config.routes.quoteBind.root}`} component={QuoteBind} />}
                {hasAccessToQB && <Route exact path={`${config.routes.quoteBind.riskDetails}/:id?`} component={RiskDetails} />}

                {isBroker && isAdmin && <Route exact path={`${config.routes.quoteBind.admin}`} component={QuoteBindAdmin} />}
                {isBroker && isAdmin && (
                  <Route exact path={`${config.routes.quoteBind.facilityDetails}/:id`} component={QuoteBindFacilityDetails} />
                )}
                {(isBroker || isUnderwriter) && (
                  <Route exact path={`${config.routes.quoteBind.aggregate}`} component={QuoteBindAggregate} />
                )}

                {isBroker && <Route exact path={`${config.routes.trip.root}`} component={Trips} />}

                {/* Claims */}
                {isClaimsTechnician && <Route exact path={`${config.routes.claimsTaskManagement.root}`} component={ClaimsTaskManagement} />}
                {/* Claims */}
                {/* Un-protected routes */}
                <Route exact path={`${config.routes.home.root}`} component={Home} />

                {!isUnderwriter && <Route exact path={`${config.routes.client.root}`} component={Client} />}
                {!isUnderwriter && <Route exact path={`${config.routes.client.item}/:id/:slug?`} component={Client} />}
                {!isUnderwriter && <Route exact path={`${config.routes.client.item}/:id/:slug/offices/:officeIds`} component={Client} />}
                {!isUnderwriter && <Route exact path={`${config.routes.market.root}`} component={Market} />}
                {!isUnderwriter && <Route exact path={`${config.routes.market.item}/:id/:slug?`} component={Market} />}
                {!isUnderwriter && <Route exact path={`${config.routes.department.root}/:id/:slug?`} component={Department} />}
                {isBroker && (
                  <Route
                    exact
                    path={`${config.routes.department.root}/:id/:slug/market-sheet`}
                    render={(props) => <Department {...props} markets />}
                  />
                )}
                {!isUnderwriter && (
                  <Route
                    exact
                    path={[
                      `${config.routes.placement.root}/:step/:id/`,
                      `${config.routes.placement.root}/openingMemo/:id/:policyId?`,
                      `${config.routes.placement.root}/marketing/:section/:id`,
                    ]}
                    component={Placement}
                  />
                )}
                {!isUnderwriter && <Route exact path={`${config.routes.placement.root}/:step/:section/:id`} component={Placement} />}
                {!isUnderwriter && <Route exact path={`${config.routes.policy.root}/:id`} component={Policy} />}
                {isDev && <Route exact path={`${config.routes.industryNews.root}`} component={IndustryNews} />}

                <Route exact path={`${config.routes.reporting.root}`} component={Reporting} />
                <Route exact path={`${config.routes.reporting.root}/:id`} component={ReportingGroup} />
                <Route exact path={`${config.routes.reporting.root}/:slug/:id`} component={ReportingDetails} />

                {/* if nothing matches, redirect to home component... */}
                <Route render={() => <Redirect to={`${config.routes.home.root}`} />} />
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>

      <Loader visible={hasLoader} label={utils.string.t('app.loading')} />
      <Notification />
      <Modal />
      {sw.isServiceWorkerUpdated ? (
        <Alert
          className={classes.alertRoot}
          variant="filled"
          severity="info"
          action={
            <Button icon={CheckIcon} size="small" text="Update" variant="contained" color="secondary" onClick={sw.updateServiceWorker} />
          }
        >
          {utils.string.t('app.updateApp')}
        </Alert>
      ) : null}
    </div>
  );
};

AppView.propTypes = {
  user: PropTypes.object.isRequired,
  isDev: PropTypes.bool.isRequired,
  isProduction: PropTypes.bool.isRequired,
  hasLoader: PropTypes.bool.isRequired,
  sw: PropTypes.shape({
    isServiceWorkerUpdated: PropTypes.bool,
    updateServiceWorker: PropTypes.func,
  }).isRequired,
};
