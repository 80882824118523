import React from 'react';
import { useDispatch } from 'react-redux';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Button } from 'components';

import { BARRIER_ISLAND_LIMIT, GOOGLE_LOCATIONS_URL, RISK_LOCATIONS_ACCURACY } from 'consts';
import { useGetAddress, useGetDistanceToCoastRequest } from 'lib/quoteBind';
import { enqueueNotification } from 'stores';
import * as utils from 'utils';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  autocomplete: {
    width: 300,
  },
}));

export default function LocationAutocomplete({ title, buttonLabel, placeholder, handleAdd, componentRestrictions, getDTC = false }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [isAdding, setIsAdding] = React.useState(false);
  const [, setSelectedValue] = React.useState(null);
  const loaded = React.useRef(false);
  const dispatch = useDispatch();
  const { mutateAsync: getDistanceToCoast } = useGetDistanceToCoastRequest();
  const { mutateAsync: getRiskAddress } = useGetAddress();

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(GOOGLE_LOCATIONS_URL, document.querySelector('head'), 'google-maps');
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    fetch({ input: inputValue, componentRestrictions }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, componentRestrictions]);

  const getAddressDetails = async (address) => {
    const response = await getRiskAddress({ searchTerm: address, componentRestrictions });

    if (response?.accuracy !== 'ROOFTOP')
      if (!RISK_LOCATIONS_ACCURACY.includes(response?.accuracy)) return { error: 'NO_ACCURATE_RESULT', address };

    const location = { lng: response.lng, lat: response.lat };
    const distanceToCoastResult = getDTC ? await getDistanceToCoast(location) : null;
    const streetAddress = response?.streetNumber
      ? `${response.streetNumber} ${response?.streetAddress}`
      : response?.streetAddress
      ? `${response.streetAddress}`
      : null;

    const result = {
      locationName: response?.locationName ? response.locationName : response?.inputAddress.split(',')[0] || '',
      city: response?.city || '',
      zip: response?.zip || '',
      county: response?.county || '',
      country: response?.country || '',
      state: response?.state || '',
      streetAddress: streetAddress || '',
      formattedAddress: response?.outputAddress || '',
      latitude: response?.lat || '',
      longitude: response?.lng || '',
      ...(getDTC && {
        distanceToCoast: distanceToCoastResult?.distanceInMiles,
        distanceToCoastInitialValue: distanceToCoastResult?.distanceInMiles,
        onBarrierIsland: distanceToCoastResult?.distanceInMiles < BARRIER_ISLAND_LIMIT ? null : false,
        inOuterBanksNc:
          distanceToCoastResult?.distanceInMiles < BARRIER_ISLAND_LIMIT && response?.state === 'North Carolina' ? null : false,
      }),
    };

    return result;
  };

  const handleAddAddress = async () => {
    setIsAdding(true);

    if (value?.description) {
      const result = await getAddressDetails(value.description);

      if (result?.error !== 'NO_ACCURATE_RESULT') {
        handleAdd(result);
        setSelectedValue({ ...result, description: value.description });
      } else {
        dispatch(
          enqueueNotification(`${result?.address} ${utils.string.t('products.multiLocation.buildingError')}`, 'error', { delay: 6000 })
        );
      }
    }
    setIsAdding(false);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" data-testid="location-autocomplete">
      <Box>
        <Autocomplete
          id="google-autocomplete"
          className={classes.autocomplete}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={title}
              placeholder={placeholder}
              variant="outlined"
              fullWidth
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleAddAddress();
                }
              }}
            />
          )}
          renderOption={(option) => {
            const matches = option?.structured_formatting?.main_text_matched_substrings || [];

            const parts = parse(
              option.structured_formatting.main_text,
              matches?.map((match) => [match.offset, match.offset + match?.length])
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </Box>
      <Box ml={2.5} mt={2}>
        <Button
          disabled={isAdding || !value}
          color="primary"
          size="small"
          icon={isAdding ? null : AddIcon}
          text={buttonLabel}
          onClick={handleAddAddress}
        />
      </Box>
    </Box>
  );
}
