import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import RiskQuotesView from './RiskQuotes.view';
import { CoverageComparison } from 'modules/QuoteBind';
import { Restricted } from 'components';
import { showModal } from 'stores';
import { useRequestToBind, useGetQuotes, useQuoteResponse, QB_RISK_DETAILS, QB_RISK_QUOTES, QB_RISK_COVERAGES } from 'lib/quoteBind';
import { RISK_QUOTE_STATUS_BOUND, RISK_QUOTE_STATUS_QUOTING, ROLE_BROKER } from 'consts';

import * as utils from 'utils';

export const RiskQuotes = ({ risk, coverageDefinition, riskIsLoading, handleReQuoteRisk }) => {
  const [isCoverageComparisonOpen, setIsCoverageComparisonOpen] = useState(false);
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: quotes, isLoading } = useGetQuotes(QB_RISK_QUOTES, risk?.id, {
    enabled: risk?.id ? true : false,
    refetchInterval: (data) => {
      const isQuoting = data?.some((quote) => quote.status === RISK_QUOTE_STATUS_QUOTING);
      return isQuoting ? 2000 : false;
    },
    keepPreviousData: false,
  });

  const { mutateAsync: postQuoteResponse } = useQuoteResponse();
  const { mutateAsync: requestToBind } = useRequestToBind();

  const riskClientCommission = risk?.client?.commission || null;
  const hasSurplusLineTax = risk?.hasSurplusLineTax ?? false;

  const hasBoundQuote =
    utils.generic.isValidArray(quotes, true) && quotes.some((q) => q.response && q.response.responseStatus === RISK_QUOTE_STATUS_BOUND);
  const hasAnyQuoteBeenReferred = quotes?.some((q) => q.requestedToDismissIssues);

  const riskIssues = risk?.issues?.filter((issue) => issue.issueType === 'REFERRED_BLOCKED') || [];
  const showCoverageComparison = coverageDefinition?.length > 0 && !hasAnyQuoteBeenReferred && !isLoading;

  const handleBindRiskQuote = async (quote, inceptionDate, expiryDate, isPreBind) => {
    const bindInfo = {
      quoteId: quote?.id,
      effectiveFrom: inceptionDate || risk?.inceptionDate,
      effectiveTo: expiryDate || risk?.expiryDate,
      riskId: risk?.id,
      responseStatus: RISK_QUOTE_STATUS_BOUND,
    };
    const result = await postQuoteResponse(bindInfo);

    if (isPreBind && result?.response?.responseStatus === RISK_QUOTE_STATUS_BOUND) {
      handlePreBind(quote, true);
    }
  };

  const executePreBind = (quote, isPreBind, isReqBind) => {
    const product = risk.riskType;
    const modalTitle = isPreBind ? `${utils.string.t('risks.preBind')}` : `${utils.string.t('risks.postBind')}`;

    dispatch(
      showModal({
        component: 'PRE_BIND_QUOTE',
        props: {
          title: modalTitle,
          fullWidth: true,
          disableBackdropClick: true,
          enableFullScreen: true,
          maxWidth: 'lg',
          componentProps: {
            product: product,
            risk,
            quote,
            isPreBind,
            isReqBind,
          },
        },
      })
    );
  };

  const handlePreBind = (quote, isPreBind) => {
    executePreBind(quote, isPreBind, false);
  };
  const handleRequestToBind = async (quote, displayPreBind) => {
    await requestToBind({ quoteId: quote.id });

    if (displayPreBind) {
      executePreBind(quote, true, true);
    }
  };

  const handleQuoteRefresh = () => {
    queryClient.invalidateQueries(QB_RISK_DETAILS, risk?.id);
    queryClient.invalidateQueries(QB_RISK_QUOTES, risk?.id);
  };

  const handleOpenCoverageComparison = async () => {
    setIsCoverageComparisonOpen((prevState) => !prevState);
  };
  const handleQuoteLoading = (isLoading) => {
    setIsQuoteLoading(isLoading);
  };

  const reFetchData = async (id) => {
    handleQuoteLoading(true);
    queryClient.invalidateQueries(QB_RISK_QUOTES, id);
    queryClient.invalidateQueries(QB_RISK_COVERAGES, id);
    handleQuoteLoading(false);
  };

  return (
    <>
      <RiskQuotesView
        isLoading={isLoading}
        riskIsLoading={riskIsLoading}
        quotes={quotes}
        riskIssues={riskIssues}
        riskClientCommission={riskClientCommission}
        hasBoundQuote={hasBoundQuote}
        riskInsuredId={risk?.insuredId}
        riskInceptionDate={risk?.inceptionDate}
        riskExpiryDate={risk?.expiryDate}
        handleBindRiskQuote={handleBindRiskQuote}
        handleRiskRefresh={handleQuoteRefresh}
        handleRequestToBind={handleRequestToBind}
        handleReQuoteRisk={handleReQuoteRisk}
        handleQuoteRefresh={handleQuoteRefresh}
        handlePreBind={handlePreBind}
        handleOpenCoverageComparison={handleOpenCoverageComparison}
        showCoverageComparison={showCoverageComparison}
        isQuoteLoading={isQuoteLoading || isLoading}
        hasSurplusLineTax={hasSurplusLineTax}
      />
      <Restricted include={[ROLE_BROKER]}>
        <>
          {showCoverageComparison ? (
            <CoverageComparison
              open={isCoverageComparisonOpen}
              riskId={risk.id}
              riskType={risk.riskType}
              handleOpenCoverageComparison={handleOpenCoverageComparison}
              handleQuoteLoading={handleQuoteLoading}
              reFetchData={reFetchData}
              coverageDefinitionFields={coverageDefinition}
            />
          ) : null}
        </>
      </Restricted>
    </>
  );
};
