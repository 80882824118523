import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

// app
import { LoginView } from './Login.view';
import * as utils from 'utils';

export default function Login() {
  const { error, loginWithRedirect } = useAuth0();
  const uiBrand = useSelector((state) => get(state, 'ui.brand'));
  const configVars = useSelector((state) => get(state, 'config.vars'));

  const handleLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${utils.string.t('app.login')} - ${utils.app.getAppName(uiBrand)}`}</title>
      </Helmet>

      <LoginView error={error} redirects={{ analytics: configVars.url.analytics }} handlers={{ login: handleLogin }} />
    </>
  );
}
