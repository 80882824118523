import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { app, generic } from 'utils';
import config from 'config';

const Auth = ({ children }) => {
  const history = useHistory();
  const [appConfig, setAppConfig] = useState(null);

  useEffect(() => {
    const getConfig = async () => {
      const config = await app.getConfig();

      setAppConfig(config);
    };

    getConfig();
  }, []);

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo);
  };

  if (!generic.isValidObject(appConfig)) return null;

  const auth0Config = {
    domain: config.auth.domain,
    clientId: config.auth.clientID,
    audience: appConfig.auth0.audience,
    redirectUri: window.location.origin + '/authentication',
    onRedirectCallback,
    cacheLocation: window?.Cypress ? 'localstorage' : 'memory',
  };

  return <Auth0Provider {...auth0Config}>{children}</Auth0Provider>;
};

export default Auth;
