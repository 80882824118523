import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import get from 'lodash/get';

// app
import { AppView } from './App.view';
import { Loader, ErrorBoundary } from 'components';
import { setBrand, selectUser, getUser, getReferenceData } from 'stores';
import * as utils from 'utils';
import { HOTJAR_ID, HOTJAR_VERSION } from 'consts';

export const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const uiBrand = useSelector((state) => get(state, 'ui.brand'));
  const uiLoaderQueue = useSelector((state) => get(state, 'ui.loader.queue', []));
  const configVars = useSelector((state) => get(state, 'config.vars'));

  const isServiceWorkerUpdated = useSelector((state) => get(state, 'ui.serviceWorkerUpdated'));
  const serviceWorkerRegistration = useSelector((state) => get(state, 'ui.serviceWorkerRegistration'));

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getUser());
    dispatch(getReferenceData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!uiBrand) {
      dispatch(setBrand(utils.app.getHostName()));
    }

    document.title = utils.app.getAppName(uiBrand);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (utils.app.isEdgeProduction()) {
      utils.app.hotjar(HOTJAR_ID, HOTJAR_VERSION);
    }
  }, []);

  if (!user?.id) {
    return <Loader />;
  }

  return (
    <ErrorBoundary>
      <AppView
        user={user}
        isDev={utils.app.isDevelopment(configVars)}
        isProduction={utils.app.isEdgeProduction()}
        hasLoader={uiLoaderQueue.length > 0}
        sw={{ isServiceWorkerUpdated: isServiceWorkerUpdated, updateServiceWorker: updateServiceWorker }}
      />
    </ErrorBoundary>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loader absolute label={utils.string.t('app.authenticating')} />,
  returnTo: () => `${window.location.origin}${window.location.pathname}`,
});
