// app
import { Button } from 'components';
import styles from './RiskIssues.styles';
import * as utils from 'utils';
// mui
import { makeStyles, Typography, Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import WarningIcon from '@material-ui/icons/Warning';

const RiskIssueCard = ({ title, subtitle, message, url, testid }) => {
  const classes = makeStyles(styles, { name: 'RiskIssueCard' })();

  return (
    <Card data-testid={`risk-data-${testid}`} classes={{ root: classes.card }}>
      <CardHeader
        avatar={<WarningIcon fontSize="large" classes={{ root: classes.icon }} />}
        title={
          <Typography variant="h3" className={classes.cardTitle} style={{ textAlign: 'left' }}>
            <Typography className={classes.sectionMessage}>{title}</Typography>
          </Typography>
        }
        subheader={subtitle}
        className={classes.cardHeader}
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <Typography classes={{ root: classes.sectionMessage }}>{message}</Typography>
      </CardContent>
      {url ? (
        <CardActions disableSpacing classes={{ root: classes.cardActions }}>
          <Button
            href={url}
            icon={LaunchIcon}
            iconPosition="right"
            rel="noopener"
            target="_blank"
            color="primary"
            size="xsmall"
            text={utils.string.t('risks.issueResolveLink')}
          />
        </CardActions>
      ) : null}
    </Card>
  );
};

const IssueCard = ({ issue, insuredSanctionsCheckResult, reInsuredSanctionsCheckResult }) => {
  const hasInsuredSanctionsCheckResult = insuredSanctionsCheckResult?.id === issue?.entityId;
  const hasReInsuredSanctionsCheckResult = reInsuredSanctionsCheckResult?.id === issue?.entityId;

  return (
    <>
      {hasInsuredSanctionsCheckResult && (
        <RiskIssueCard
          title={insuredSanctionsCheckResult.name}
          subtitle={utils.string.t('app.insured')}
          message={insuredSanctionsCheckResult.message}
          url={insuredSanctionsCheckResult.url}
          testid="insured"
        />
      )}
      {hasReInsuredSanctionsCheckResult && (
        <RiskIssueCard
          title={reInsuredSanctionsCheckResult.name}
          subtitle={utils.string.t('app.reInsured')}
          message={reInsuredSanctionsCheckResult.message}
          url={reInsuredSanctionsCheckResult.url}
          testid="reinsured"
        />
      )}
    </>
  );
};

export default IssueCard;
