import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// mui
import { Box, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ReactComponent as Loading } from 'assets/svg/loading.svg';
// app
import { Button, Empty, Pagination, Translate } from 'components';
import { AlphabeticalFilter } from 'components/AlphabeticalFilter';

import { DEFAULT_CHARACTERS } from 'consts';
import config from 'config';
import * as utils from 'utils';

import { UserCard } from './UserCard';

const characters = [...DEFAULT_CHARACTERS, { value: '', label: 'All' }];

export const AdminUserView = ({
  pagination,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreateUser,
  handleFilterClick,
  selectedCharacter,
  userList = [],
}) => (
  <div>
    <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <AlphabeticalFilter handleClick={handleFilterClick} selectedCharacter={selectedCharacter} characters={characters} />
      </Box>
      <Button
        icon={AddIcon}
        size="xsmall"
        text={<Translate label="admin.createUser" />}
        data-testid="admin-create-button"
        onClick={() => handleCreateUser()}
        variant="outlined"
        light
        style={{ margin: '4px' }}
      />
    </Box>
    <Grid container spacing="3" style={{ marginTop: 20 }}>
      {userList?.length > 0 ? (
        userList.map((user) => (
          <Grid key={user.id} item xs={12} sm={6} md={4} lg={3} data-testid={`user-${user.id}`}>
            <Link
              to={{
                pathname: `${config.routes.admin.userDetails}/${user.id}`,
                state: { user },
              }}
              style={{ textDecoration: 'none' }}
            >
              <UserCard key={user.id} user={user} />
            </Link>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Empty height={400} title={utils.string.t('app.noResult')} icon={<Loading />} padding />
        </Grid>
      )}
    </Grid>

    <Grid container>
      <Grid item xs={12}>
        <Pagination
          page={pagination.page}
          count={pagination.rowsTotal}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={config.ui.gridPagination.options}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  </div>
);

AdminUserView.propTypes = {
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      active: PropTypes.bool,
      isAdmin: PropTypes.bool,
      role: PropTypes.string,
    })
  ),
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsTotal: PropTypes.number,
  }).isRequired,
  selectedCharacter: PropTypes.string,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleCreateUser: PropTypes.func.isRequired,
  handleFilterClick: PropTypes.func,
};
