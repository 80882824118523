import React from 'react';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker } from '@material-ui/pickers';

// Edge
import { Button } from 'components';
import { useGetEndorsementRiskData } from 'lib/quoteBind';
import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {},
  typography: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
}));

const isValid = (effectiveDate, minDate, maxDate) => {
  return utils.date.isValid(effectiveDate) && utils.date.isBetween(effectiveDate, minDate, utils.date.addDays(maxDate, 1));
};

export const NewEndorsement = ({ handleAddEndorsement, latestEffectiveEndorsementDate, policy, earliestBindPeriod }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [effectiveDate, setEffectiveDate] = React.useState(null);
  const { effectiveFrom, effectiveTo } = policy?.response || null;

  const { isFetching, refetch } = useGetEndorsementRiskData(policy?.riskId, effectiveDate);
  let maxDate = null;
  const minDate = effectiveFrom;

  if (latestEffectiveEndorsementDate) {
    maxDate = latestEffectiveEndorsementDate;
  } else {
    maxDate =
      utils.date.diffDays(utils.date.today(), effectiveTo) > earliestBindPeriod
        ? effectiveTo
        : utils.date.addDays(utils.date.today(), earliestBindPeriod);
  }

  const handleEffectiveDateChange = (date) => {
    setEffectiveDate(date);
  };

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setEffectiveDate(null);
    setOpen(false);
  };

  const handleAddEndorsementClick = async () => {
    const result = await refetch();
    if (result.isSuccess) {
      const riskData = result.data;
      handleAddEndorsement(riskData, effectiveDate);
      handleClose();
    }
  };

  return (
    <div className={classes.root}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: false,
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={2}>
              <Box p={2}>
                <Typography className={classes.typography}>{utils.string.t('risks.endorsementFrom')}</Typography>
                <KeyboardDatePicker
                  onChange={(date) => handleEffectiveDateChange(date)}
                  autoOk={true}
                  placeholder={utils.date.today('DD-MM-yyyy')}
                  value={effectiveDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  format="DD-MM-yyyy"
                />
              </Box>
              <Divider />
              <Box p={1} display="flex" justifyContent="flex-end">
                <Button variant="text" size="xsmall" onClick={handleClose} text={utils.string.t('app.cancel')} />
                <Button
                  variant="contained"
                  disabled={!isValid(effectiveDate, minDate, maxDate) || isFetching}
                  size="xsmall"
                  color="primary"
                  onClick={handleAddEndorsementClick}
                  text={isFetching ? `Loading` : utils.string.t('app.add')}
                  style={{
                    marginLeft: 10,
                  }}
                />
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Button
        icon={AddIcon}
        size="xsmall"
        variant="outlined"
        color="primary"
        text={utils.string.t('app.endorsement')}
        onClick={handleAddClick}
      />
    </div>
  );
};
