import { firstBy } from 'thenby';

// mui
import { Box, Typography } from '@material-ui/core';

// app
import { useQuoteBindStyles } from 'modules/QuoteBind';
import { FormGrid } from 'components';
import { PolicyCard, PolicyCardSkeleton } from './PolicyCard';
import { Endorsements } from './Endorsement';
import { useGetQuotes, useGetEndorsements, QB_RISK_QUOTES } from 'lib/quoteBind';
import { RISK_QUOTE_STATUS_BOUND, RISK_STATUS_EXPIRED, RISK_QUOTE_STATUS_QUOTING, RISK_QUOTE_STATUS_PENDING } from 'consts';
import * as utils from 'utils';

export const RiskPolicyExpired = ({ risk, riskIsLoading }) => {
  const classes = useQuoteBindStyles();

  const { data: quotes, isLoading } = useGetQuotes(QB_RISK_QUOTES, risk?.id, {
    enabled: risk?.id ? true : false,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  const { data: endorsements } = useGetEndorsements(risk?.id, {
    enabled: risk?.id ? true : false,
    refetchInterval: (data) => {
      const isQuoting = data?.some((quote) => quote.status === RISK_QUOTE_STATUS_QUOTING);
      return isQuoting ? 2000 : false;
    },
    cacheTime: 60 * 1000,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });

  const effectiveEndorsements = endorsements?.filter((endorsement) => endorsement.status === RISK_QUOTE_STATUS_BOUND) || [];
  const effectiveEndorsementsSorted =
    utils.sort
      .arrayNestedPropertyValue(effectiveEndorsements, 'response.responseDate', 'asc')
      ?.map((endorsement, index) => {
        return {
          ...endorsement,
          index: index + 1,
        };
      })
      .sort(firstBy(utils.sort.array('date', 'effectiveFrom', 'asc'))) || [];

  const pendingEndorsements = endorsements?.filter((endorsement) => endorsement.status === RISK_QUOTE_STATUS_PENDING) || [];

  const policy = quotes?.find((q) => q.response && q.response.responseStatus === RISK_QUOTE_STATUS_BOUND) || null;
  const policyCurrency = policy?.currency || null;
  const hasSurplusLineTax = risk?.hasSurplusLineTax ?? false;

  if (isLoading || riskIsLoading || !policy) {
    return (
      <FormGrid item xs={12} sm={6} md={6} lg={4}>
        <PolicyCardSkeleton />
      </FormGrid>
    );
  }

  const isCancelation = risk?.risk?.riskStatus === RISK_STATUS_EXPIRED;

  return (
    <Box className={classes.root} data-testid="RiskPolicyExpired">
      <Box className={classes.header} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="start">
          <Typography variant="h3" className={classes.title}>
            {utils.string.t('app.expiredPolicy')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.content}>
        <FormGrid container spacing={2}>
          <FormGrid item xs={12} sm={6} md={6} lg={4}>
            <PolicyCard
              policy={policy}
              hasBoundQuote
              endorsements={effectiveEndorsementsSorted}
              hasAnyEndorsements={effectiveEndorsements?.length > 0 || pendingEndorsements?.length}
              isExpired={true}
              isCancelation={isCancelation}
              inceptionDate={risk?.inceptionDate}
              expiryDate={risk?.expiryDate}
              hasSurplusLineTax={hasSurplusLineTax}
            />
          </FormGrid>
          {effectiveEndorsementsSorted?.length > 0 ? (
            <FormGrid item xs={12} sm={6} md={6} lg={4}>
              <Endorsements
                title={utils.string.t('app.endorsement_plural')}
                endorsements={effectiveEndorsementsSorted}
                policyCurrency={policyCurrency}
                riskType={policy?.riskType}
                displayIndex={true}
                testid="effectiveEndorsements"
              />
            </FormGrid>
          ) : null}
          {pendingEndorsements?.length > 0 ? (
            <FormGrid item xs={12} sm={6} md={6} lg={4}>
              <Endorsements
                title={utils.string.t('app.pendingEndorsement_plural')}
                canCancel={true}
                endorsements={pendingEndorsements}
                policyCurrency={policyCurrency}
                riskType={policy?.riskType}
                testid="pendingEndorsements"
              />
            </FormGrid>
          ) : null}
        </FormGrid>
      </Box>
    </Box>
  );
};
