import { useState } from 'react';

// mui
import { IconButton } from '@material-ui/core';
import CompareRoundedIcon from '@material-ui/icons/CompareRounded';

// app
import { ModalDialog } from 'components';
import { EndorsementComparisonDetails } from './EndorsementComparisonDetails';
import * as utils from 'utils';

export const EndorsementComparison = ({ prePatchRisk, postPatchRisk, effectiveFrom, riskType }) => {
  const [isEndorsementComparisonOpen, setEndorsementComparisonOpen] = useState(false);

  const handleCloseEndorsementComparison = () => {
    setEndorsementComparisonOpen(false);
  };

  const handleOpenEndorsementComparison = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setEndorsementComparisonOpen(true);
  };

  return prePatchRisk && postPatchRisk ? (
    <>
      <ModalDialog
        title={`Effective from -  ${utils.date.formatDateWithParameter(effectiveFrom, 'MMM DD, YYYY')}`}
        fullWidth
        disableBackdropClick
        enableFullScree={false}
        maxWidth="xl"
        cancelHandler={handleCloseEndorsementComparison}
        hideModal={handleCloseEndorsementComparison}
        visible={isEndorsementComparisonOpen}
      >
        <EndorsementComparisonDetails riskDataValues={prePatchRisk} currentEndorsementValues={postPatchRisk} riskType={riskType} />
      </ModalDialog>

      <IconButton size="small" onClick={handleOpenEndorsementComparison} data-testid="compare-endorsements">
        <CompareRoundedIcon fontSize="small" />
      </IconButton>
    </>
  ) : null;
};
