import { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';

// app
import { QuoteBindDraftRiskView } from './QuoteBindDraftRisk.view';
import { showModal } from 'stores';
import { useGetDraftRiskList, useGetProducts, useDeleteDraftRisk, DRAFT_RISKS, QB_PRODUCTS } from 'lib/quoteBind';
import * as utils from 'utils';

export const QuoteBindDraftRisk = ({ displayStyle }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState({});
  const brand = useSelector((state) => state.ui.brand);

  const { data: programmesProductsData } = useGetProducts(QB_PRODUCTS);
  const { data: draftListData, isLoading: draftRiskListLoading } = useGetDraftRiskList(DRAFT_RISKS, params);
  const { mutate: deleteDraftRisk } = useDeleteDraftRisk();

  const draftList = draftListData?.content || [];
  const riskProducts = useMemo(() => programmesProductsData?.data || [], [programmesProductsData]);

  const draftPaginationObj = {
    page: 1,
    rowsTotal: draftListData?.pagination?.totalElements || 0,
    rowsPerPage: draftListData?.pagination?.size || 0,
  };

  // draft pagination
  const handleDraftChangePage = (event) => {
    setParams({ ...params, page: event.current.value + 1 });
  };

  const handleDraftChangeRowsPerPage = (rowsPerPage) => {
    setParams({ ...params, size: rowsPerPage });
  };

  const popoverActions = [
    {
      id: 'edit-draft-risk',
      label: 'app.edit',
      callback: (popoverData) => handleEditDraftRisk(popoverData),
    },
    {
      id: 'delete-draft-risk',
      label: 'app.delete',
      callback: (popoverData) => handleDeleteDraftRisk(popoverData),
    },
  ];

  const handleDeleteDraftRisk = (popoverData) => {
    dispatch(
      showModal({
        component: 'CONFIRM_DELETE',
        props: {
          title: 'products.tabs.draftRisks.deleteDraftRisk',
          fullWidth: true,
          maxWidth: 'xs',
          disableAutoFocus: true,
          componentProps: {
            submitHandler: () => {
              deleteDraftRisk({ draftId: get(popoverData, 'id'), isSubmitted: false });
            },
          },
        },
      })
    );
  };

  const handleEditDraftRisk = (popoverData) => {
    const draftId = popoverData.id;
    const riskData = popoverData.risk;
    let product = {};
    riskProducts.forEach((rp) => {
      if (rp.value.toLowerCase() === riskData.riskType.toLowerCase()) {
        product = rp;
      }
    });
    dispatch(
      showModal({
        component: 'ADD_EDIT_QUOTE_BIND',
        props: {
          title: product.label,
          fullWidth: true,
          disableBackdropClick: true,
          enableFullScreen: true,
          maxWidth: 'xl',
          componentProps: {
            product,
            riskData,
            draftId,
          },
        },
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>{`${utils.string.t('products.title')} - ${utils.app.getAppName(brand)} - ${utils.string.t(
          'products.tabs.draftRisks.label'
        )}`}</title>
      </Helmet>
      <QuoteBindDraftRiskView
        displayStyle={displayStyle}
        pagination={draftPaginationObj}
        listDraftRisk={draftList}
        draftRiskListLoading={draftRiskListLoading}
        popoverActions={popoverActions}
        riskProducts={riskProducts}
        handlers={{
          handleEditDraftRisk: handleEditDraftRisk,
          handleDeleteDraftRisk: handleDeleteDraftRisk,
          changePage: handleDraftChangePage,
          changeRowsPerPage: handleDraftChangeRowsPerPage,
        }}
      />
    </>
  );
};
