import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

// app
import { enqueueNotification, hideModal } from 'stores';
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import isEmpty from 'lodash/isEmpty';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_QUOTES } from 'lib/quoteBind/queryKeys';

// risks
export const usePatchQuote = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.risks.usePatchQuote',
      err: err,
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (res) => {
    queryClient.invalidateQueries(QB_RISK_QUOTES, res.riskId);

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.patchRiskQuote.success', 'success'));
  };

  return useMutation(
    async (params) => {
      const { formData, quote, accept = false } = params;

      if (!formData || isEmpty(formData) || isEmpty(quote) || !quote.id) {
        throw new Error('Invalid params');
      }

      const data = {
        ...quote,
        grossPremium: formData.grossPremium,
        validUntil: formData.validUntil,
      };

      const path = accept ? `quotes/${quote.id}/patch?acceptQuote=true` : `quotes/${quote.id}/patch`;

      const result = await quoteBindAxios.post(path, data);
      return result?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
