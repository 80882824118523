import PropTypes from 'prop-types';

// app
import styles from './Login.styles';
import { Button, Translate } from 'components';
import { ReactComponent as LogoEdge } from 'assets/svg/logo-edge-stacked.svg';

// mui
import { makeStyles, Box, Paper, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

LoginView.propTypes = {
  error: PropTypes.string,
  redirects: PropTypes.shape({
    analytics: PropTypes.string,
  }).isRequired,
  handlers: PropTypes.shape({
    login: PropTypes.func,
  }).isRequired,
};

export function LoginView({ error, redirects, handlers }) {
  const classes = makeStyles(styles, { name: 'Login' })();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.grid} data-testid="login">
      <Grid item xs={12}>
        <Paper elevation={1} className={classes.paper}>
          <Box className={classes.logo}>
            <LogoEdge data-testid="edge-logo" />
          </Box>

          {error && (
            <Box mt={-2} mb={3}>
              <Alert severity="warning" icon={false}>
                <Typography>{error.message}</Typography>
              </Alert>
            </Box>
          )}

          <Button
            color="primary"
            text={<Translate label={error ? 'app.tryAgain' : 'app.signIn'} />}
            onClick={handlers.login}
            data-testid="sign-in"
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
