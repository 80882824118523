import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Button,
  FormActions,
  FormAutocompleteMui,
  FormContainer,
  FormDate,
  FormFields,
  FormGrid,
  FormSelect,
  FormSwitch,
  FormText,
} from 'components';

import { CLAIMS_TASKS_STATUS_COMPLETED } from 'consts';
import { usePostTask } from 'lib/claimsTaskManagement';
import * as utils from 'utils';

// app
import { useAddEditClaimsTaskStyles } from './AddEditClaimsTask.styles';

export const AddEditClaimsTaskForm = ({ fields, defaultValues, handleClose, taskId = null }) => {
  const classes = useAddEditClaimsTaskStyles();
  const { mutate: createUpdateTask, isLoading: taskIsLoading } = usePostTask();

  const validationSchema = utils.form.getValidationSchema(fields);

  const { control, handleSubmit, formState, setValue, watch } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });
  const { errors } = formState;
  const statusValue = watch('status');

  const submitHandler = (submittedData) => {
    const taskData = {
      ...submittedData,
      ...(taskId && { id: taskId }),
      reminder: {
        date: utils.date.formatDateWithParameter(submittedData.reminderDate, 'YYYY-MM-DD'),
        notes: submittedData.notes,
      },
      primaryHandler: submittedData.primaryHandler.email,
      secondaryHandler: submittedData.secondaryHandler.email,
      emailRecipient: submittedData.emailRecipient.email,
    };
    createUpdateTask({
      data: taskData,
    });
  };

  const getDateField = (fieldName, status) => {
    const field = utils.form.getFieldProps(fields, fieldName);

    if (fieldName === 'completedDate' && status !== CLAIMS_TASKS_STATUS_COMPLETED) {
      setValue(fieldName, null);
      return {
        ...field,
        muiComponentProps: {
          ...field.muiComponentProps,
          ...(status !== CLAIMS_TASKS_STATUS_COMPLETED && { disabled: true }),
        },
      };
    }

    return {
      ...field,
      muiComponentProps: {
        ...field.muiComponentProps,
      },
    };
  };

  return (
    <div className={classes.root}>
      <FormContainer type="dialog" onSubmit={handleSubmit(submitHandler)} data-testid="form-add-edit-claims-task">
        <FormFields type="dialog">
          <FormGrid container spacing={2}>
            <FormGrid item xs={6} md={4}>
              <FormDate {...utils.form.getFieldProps(fields, 'emailReceivedDate')} control={control} error={errors.emailReceivedDate} />
            </FormGrid>
            <FormGrid item xs={6} sm={4}>
              <FormAutocompleteMui
                {...utils.form.getFieldProps(fields, 'emailRecipient')}
                control={control}
                error={errors.emailRecipient}
              />
            </FormGrid>
            <FormGrid item xs={6} sm={4}>
              <FormAutocompleteMui
                {...utils.form.getFieldProps(fields, 'primaryHandler')}
                control={control}
                error={errors.primaryHandler}
              />
            </FormGrid>
            <FormGrid item xs={6} sm={4}>
              <FormAutocompleteMui
                {...utils.form.getFieldProps(fields, 'secondaryHandler')}
                control={control}
                error={errors.secondaryHandler}
              />
            </FormGrid>
            <FormGrid item xs={6} sm={4}>
              <FormAutocompleteMui {...utils.form.getFieldProps(fields, 'division')} control={control} error={errors.division} />
            </FormGrid>
            <FormGrid item xs={6} sm={4}>
              <FormAutocompleteMui {...utils.form.getFieldProps(fields, 'taskDetail')} control={control} error={errors.taskDetail} />
            </FormGrid>
          </FormGrid>
          <FormGrid container spacing={2} style={{ marginTop: '1rem' }}>
            <FormGrid item xs={4} sm={4}>
              <FormSelect {...utils.form.getFieldProps(fields, 'status')} control={control} error={errors.status} />
            </FormGrid>
            <FormGrid item xs={4} sm={4}>
              <FormDate {...getDateField('completedDate', statusValue)} control={control} error={errors.completedDate} />
            </FormGrid>
            <FormGrid item xs={4} sm={4}>
              <FormDate
                {...utils.form.getFieldProps(fields, 'sentToUnderwriterDate')}
                control={control}
                error={errors.sentToUnderwriterDate}
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormText {...utils.form.getFieldProps(fields, 'description')} control={control} error={errors.description} />
            </FormGrid>
            <FormGrid item xs={6} sm={4}>
              <FormText {...utils.form.getFieldProps(fields, 'ucr')} control={control} error={errors.ucr} />
            </FormGrid>
            <FormGrid item xs={6} sm={4}>
              <FormText {...utils.form.getFieldProps(fields, 'umr')} control={control} error={errors.umr} />
            </FormGrid>
            <FormGrid item xs={6} sm={4}>
              <FormText {...utils.form.getFieldProps(fields, 'insured')} control={control} error={errors.insured} />
            </FormGrid>
            <FormGrid item xs={3} sm={4}>
              <FormSwitch size="small" {...utils.form.getFieldProps(fields, 'denial')} control={control} />
            </FormGrid>
          </FormGrid>
          <FormGrid container spacing={2}>
            <FormGrid item xs={4}>
              <FormDate {...getDateField('reminderDate', statusValue)} control={control} error={errors.reminderDate} />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormText {...utils.form.getFieldProps(fields, 'notes')} control={control} error={errors.notes} />
            </FormGrid>
          </FormGrid>
        </FormFields>

        <FormActions type="dialog">
          <Button
            text={utils.string.t('app.close')}
            variant="text"
            disabled={formState.isSubmitting || taskIsLoading}
            onClick={handleClose}
          />
          <Button text={utils.string.t('app.save')} type="submit" disabled={formState.isSubmitting || taskIsLoading} color="primary" />
        </FormActions>
      </FormContainer>
    </div>
  );
};
