import { createContext, useCallback, useState } from 'react';

import { FUZZY, PREVIOUS, REAL } from 'consts';

const UPLOADN_BDX_INITIAL_CONTEXT = {
  setRequestId: (requestId) => requestId,
  setFileReady: (isReady) => isReady,
  setPassword: (password) => password,
  setRowId: (rowId) => rowId,
  setExpectedFields: (expectedFields) => expectedFields,
  setSpreadsheetFields: (spreadsheetFields) => spreadsheetFields,
  setStoredMappingValues: (mappingValues) => mappingValues,
  setStoredFileUploadValues: (fileUploadValues) => fileUploadValues,
  getMappingFormFields: () => [],
  setSheetName: (sheetName) => sheetName,
  setThirdPartyId: (thirdPartyId) => thirdPartyId,
  isFileReady: false,
  sanctionsCheckOnly: false,
  isTest: false,
  requestId: null,
  yearMonthSubmission: null,
  bdxType: null,
  facility: null,
  password: null,
  rowId: null,
  fileRequestId: null,
  sheetName: null,
  thirdPartyId: null,
  storedMappingValues: {},
  storedFileUploadValues: {},
  defaultBdxType: null,
};

export const UploadBDXContext = createContext(UPLOADN_BDX_INITIAL_CONTEXT);

export const UploadBDXContextProvider = ({
  children,
  sanctionsCheckOnly,
  isTest,
  yearMonthSubmission,
  bdxType,
  facility,
  fileRequestId,
  thirdPartyId,
  operationalClientId,
  isCoverHolderUpload,
  validatedCoverHolderData,
  setvalidatedCoverHolderData,
  defaultBdxType,
}) => {
  const [requestId, setRequestId] = useState(null);
  const [isFileReady, setFileReady] = useState(false);
  const [password, setPassword] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [sheetName, setSheetName] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [tpa, setThirdPartyId] = useState(thirdPartyId);

  const [expectedFields, setExpectedFields] = useState([]);
  const [spreadsheetFields, setSpreadsheetFields] = useState([]);
  const [storedMappingValues, setStoredMappingValues] = useState({});
  const [storedFileUploadValues, setStoredFileUploadValues] = useState({});

  const getDefaultField = (field, spreadsheetFields) => {
    if (field.matchType === REAL || field.matchType === FUZZY || field.matchType === PREVIOUS) {
      return spreadsheetFields.find((sf) => sf.fieldId === field.matchFieldId);
    }

    return null;
  };

  const getMappingFormFields = useCallback(
    () =>
      expectedFields.map((field, index) => ({
        name: `field_${index}`,
        label: field.odsField,
        type: 'select',
        value: getDefaultField(field, spreadsheetFields),
        options: spreadsheetFields.length > 0 ? spreadsheetFields : [],
        optionKey: 'fieldId',
        optionLabel: 'mapperField',
        matchType: field.matchType,
        getOptionSelected: (option, value) => option.fieldId === value.fieldId,
        getOptionLabel: (option) => option.mapperField,
        sanctionField: field.sanctionField,
      })),
    [spreadsheetFields, expectedFields]
  );

  return (
    <UploadBDXContext.Provider
      value={{
        setRequestId,
        setFileReady,
        setPassword,
        setRowId,
        setExpectedFields,
        setSpreadsheetFields,
        getMappingFormFields,
        setStoredMappingValues,
        setStoredFileUploadValues,
        setSheetName,
        setThirdPartyId,
        thirdPartyId: tpa,
        sanctionsCheckOnly,
        isFileReady,
        isTest,
        requestId,
        yearMonthSubmission,
        bdxType,
        facility,
        password,
        rowId,
        storedMappingValues,
        storedFileUploadValues,
        fileRequestId,
        sheetName,
        sheets,
        setSheets,
        operationalClientId,
        isCoverHolderUpload,
        validatedCoverHolderData,
        setvalidatedCoverHolderData,
        defaultBdxType,
      }}
    >
      {children}
    </UploadBDXContext.Provider>
  );
};
