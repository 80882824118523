import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Avatar, Chip } from 'components';

import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '5px',
    overflow: 'hidden',
    position: 'relative',
  },
  disabled: {
    position: 'absolute',
    right: '10px',
    top: '5px',
    zIndex: 30,
    overflow: 'hidden',
    textAlign: 'right',
  },
  disabledChip: {
    padding: theme.spacing(1),
    height: '22px',
  },
  disabledChipLabel: {
    padding: 0,
    fontWeight: 600,
    fontSize: '12px',
  },
  disabledOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.5)',
    zIndex: 20,
  },
  tooltip: {
    padding: 10,
    fontSize: 12,
  },
  userCardTop: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    ...(props.isBroker && { backgroundColor: theme.palette.primary.main }),
    ...(props.isProducer && { backgroundColor: theme.palette.success.dark }),
    ...(props.isUnderwriter && { backgroundColor: theme.palette.error.main }),
    ...(props.isClaimsTechnician && { backgroundColor: theme.palette.alert.dark }),
    position: 'relative',
    justifyContent: 'center',
    paddingTop: '0px',
  }),
  name: {
    color: theme.palette.primary.contrastText,
    fontSize: '21px',
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 5,
    textAlign: 'center',
    '&:first-child ': {
      marginTop: 30,
    },
  },
  role: {
    color: theme.palette.primary.contrastText,
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: -10,
  },
  avatarBox: { position: 'relative', display: 'flex', flex: 1, justifyContent: 'center', width: '100%' },
  bgAvatar: {
    width: '100%',
    height: '62px',
    '-webkit-mask': 'url(/shape-avatar.svg) center center / contain no-repeat',
    backgroundColor: 'rgb(255, 255, 255)',
    zIndex: '10',
    bottom: '-26px',
    position: 'absolute',
  },
  avatar: {
    zIndex: 11,
    transform: 'translateY(50%)',
  },
  userInfo: (props) => ({
    paddingBottom: '20px',
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  userRole: { fontSize: '14px', fontWeight: 500, textAlign: 'center', lineHeight: 1.2, minHeight: '2.4rem' },
  departments: { padding: 20, minHeight: 72, borderTop: '1px solid #E6E6E6', display: 'flex', justifyContent: 'center' },
  departmentsLabel: { fontSize: '13px', fontWeight: 500, color: theme.palette.grey[700] },
}));

export const UserCard = ({ user, children, isInnerView = false }) => {
  const classes = useStyles({
    isBroker: utils.user.isBroker(user),
    isProducer: utils.user.isProducer(user),
    isUnderwriter: utils.user.isUnderwriter(user),
    isClaimsTechnician: utils.user.isClaimsTechnician(user),
    isDisabled: !user?.active,
    isInnerView,
  });

  const isDisabled = !user?.active;
  const firstName = user?.firstName?.[0] ? user.firstName[0] : ' ';
  const lastName = user?.lastName?.[0] ? user.lastName[0] : ' ';
  const initials = `${firstName}${lastName}`;

  return (
    <Paper elevation={2} className={classes.root}>
      {isDisabled ? (
        <>
          <div className={classes.disabledOverlay} />
          <div className={classes.disabled}>
            <Chip
              label="Disabled"
              type="error"
              nestedClasses={{
                root: classes.disabledChip,
                label: classes.disabledChipLabel,
              }}
            />
          </div>
        </>
      ) : null}
      <Box className={classes.userCardTop}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h3" classes={{ h3: classes.name }}>
            {user.fullName}
          </Typography>
          <Typography variant="h5" classes={{ h5: classes.role }}>
            {utils.user.getRoleString(user.role)}
            {user.isAdmin ? ` - Admin` : ''}
          </Typography>
        </Box>
        <Box className={classes.avatarBox}>
          <Box as="span" className={classes.bgAvatar} />
          <Avatar text={initials} size={64} classes={{ root: classes.avatar }} />
        </Box>
      </Box>
      <Box className={classes.userInfo}>
        <Typography variant="body2" classes={{ body2: classes.userRole }}>
          {user?.addedBinderManagement ? `Programme Management` : ''}
          {user.programmesUserId && user.addedBinderManagement ? ` | ` : ''}
          {user.programmesUserId ? `Quote & Bind` : ''}
          {user.addedReporting && (user.programmesUserId || user.addedBinderManagement) ? ` | ` : ''}
          {user.addedReporting ? `Reporting` : ''}
        </Typography>
      </Box>
      {children || null}
    </Paper>
  );
};
