import { useQuery } from 'react-query';

// app
import { quoteBindAxios } from 'lib/quoteBind';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';
import { QB_RISK_ENDORSEMENTS } from 'lib/quoteBind/queryKeys';

// risks
export const useGetEndorsements = (riskId, options) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.queries.useGetEndorsementRiskData',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [QB_RISK_ENDORSEMENTS, riskId],
    async () => {
      const result = await quoteBindAxios.get(`endorsements/risk/${riskId}`);
      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      ...options,
    }
  );
};
