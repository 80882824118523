// currency
export const CURRENCY_USD = 'USD';
export const CURRENCY_GBP = 'GBP';

// origin
export const ORIGIN_GXB = 'GXB';
export const ORIGIN_EDGE = 'OMS';

// status
export const STATUS_MARKET_PENDING = 'Pending';
export const STATUS_MARKET_QUOTED = 'Quoted';
export const STATUS_MARKET_DECLINED = 'Declined';
export const STATUS_PLACEMENT_BOUND = 'Bound';
export const STATUS_PLACEMENT_NTU = 'NTU';
export const STATUS_POLICY_NTU = 'Not Taken Up';
export const SUCCESS = 'SUCCESS';
export const ACCEPTED = 'ACCEPTED';
export const REAL = 'REAL';
export const PREVIOUS = 'PREVIOUS';
export const FUZZY = 'FUZZY';
export const NO_MATCH = 'NO_MATCH';

// role
export const ROLE_BROKER = 'BROKER';
export const ROLE_COVERHOLDER = 'COVERHOLDER';
export const ROLE_PRODUCER = 'PRODUCER';
export const ROLE_UNDERWRITER = 'UNDERWRITER';
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_CLAIMS = 'CLAIMS';

// brands
export const APP_NAME = 'Edge Ardonagh Specialty';
export const APP_NAME_SHORT = 'Edge';
export const BRAND_NAME = 'Ardonagh Specialty';
export const BRAND = 'ardonagh_specialty';
export const BRAND_PRICEFORBES = 'priceforbes';
export const BRAND_PRICEFORBES_NAME = 'Price Forbes';
export const BRAND_PRICEFORBES_APPNAME = 'EDGE Price Forbes Online';
export const BRAND_BISHOPSGATE = 'bishopsgate';
export const BRAND_BISHOPSGATE_NAME = 'Bishopsgate';
export const BRAND_BISHOPSGATE_APPNAME = 'EDGE Bishopsgate Online';

// risk issues
export const RISK_ISSUE_SANCTIONS_BLOCKED = 'SANCTIONS_BLOCKED';
export const RISK_ISSUE_SANCTIONS_DOWN = 'SANCTIONS_DOWN';
export const RISK_ISSUE_SANCTIONS = [RISK_ISSUE_SANCTIONS_BLOCKED, RISK_ISSUE_SANCTIONS_DOWN];
export const RISK_ISSUE_STATUS_PASSED = 'PASSED';
export const RISK_ISSUE_INCEPTION_DATE_EXPIRED = 'INCEPTION_DATE_EXPIRED';

// key
export const KEYCODE = {
  Tab: 9,
  Enter: 13,
  Shift: 16,
  Escape: 27,
  Space: 32,
  ArrowLeft: 37,
  ArrowUp: 38,
  ArrowRight: 39,
  ArrowDown: 40,
};

// file extension
export const FILE_EXTENSION = {
  'application/json': 'json',
  'application/msexcel': 'xlsx',
  'application/msword': 'doc',
  'application/pdf': 'pdf',
  'application/rtf': 'rtf',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.oasis.opendocument.presentation': 'odp',
  'application/vnd.oasis.opendocument.spreadsheet': 'ods',
  'application/vnd.oasis.opendocument.text': 'odt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/xml': 'xml',
  'application/zip': 'zip',
  'text/plain': 'txt',
  'text/xml': 'xml',
};

// application type
export const APPLICATION_TYPE_JSON = 'application/json';
export const APPLICATION_TYPE_TEXT = 'text/plain';

// placement
export const PLACEMENT_DECLARATION = 'DECLARATION';
export const PLACEMENT_OPEN_MARKET = 'OPEN_MARKET';
export const PLACEMENT_LINESLIP = 'LINESLIP';
export const PLACEMENT_BINDER = 'BINDER';

export const FOLDER_MODELLING = 'MODELLING';
export const FOLDER_SUBMISSIONS = 'SUBMISSIONS';
export const FOLDER_CORRESPONDENCE = 'CORRESPONDENCE';

export const PLACEMENT_SOURCE = {
  company: 'Price Forbes',
  logo: 'https://edgeassets.blob.core.windows.net/logo/price-forbes.png',
  gxbInstance: 'GXB London',
};

// order/group markets by statusId in this order
export const MARKETS_STATUS_ID_ORDER = [2, 1, 3, null];
export const MARKET_COLORS = [null, '#EDAC00', '#2CC6AB', '#E93D4C'];

// Format excel data
export const COUNTRY_RATES = 'COUNTRY_RATES';

// Bulk select
export const SELECTED = 'YES';
export const DESELECTED = 'NO';
export const SELECTALL = 'ALL';

// Hotjar
export const HOTJAR_ID = 2416850;
export const HOTJAR_VERSION = 6;

// Modelling
export const MODELLING_QUOTING = 'QUOTING';
export const MODELLING_BOUND = 'BOUND';

// APP
export const DEFAULT_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((character) => ({
  value: character,
  label: character,
}));

export const REPORT_ID = 'reportId';

export const XLSX_FILE_EXTENSION = '.xlsx';
