import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useConfirmCancelModal } from 'components/Modal/useConfirmCancelModal';

import { FUZZY, NO_MATCH, PREVIOUS, REAL, UPLOAD_BDX_STEPS } from 'consts';
import { useConfirmBDXRow, useValidateBDX, useValidateBDXCoverHolder } from 'lib/binderManagement';
import { showModal } from 'stores';
import * as utils from 'utils';

import { UploadBDXContext } from '../UploadBDX.context';

import { FieldMappingView } from './FieldMapping.view';

export default function FieldMapping({ handlers }) {
  const dispatch = useDispatch();
  const {
    getMappingFormFields,
    rowId,
    requestId,
    setExpectedFields,
    setSpreadsheetFields,
    setRowId,
    storedMappingValues,
    setStoredMappingValues,
    sheetName,
    isCoverHolderUpload,
    validatedCoverHolderData,
  } = useContext(UploadBDXContext);
  const { mutateAsync: confirmBDXRow } = useConfirmBDXRow();

  const { mutateAsync: validateBDX } = useValidateBDX();
  const { mutateAsync: validateBDXCoverHolder } = useValidateBDXCoverHolder();
  const validateBdx = isCoverHolderUpload ? validateBDXCoverHolder : validateBDX;
  // in future the same endpoint will be used and this ternary will be unnecessary
  // TODO - change single uploadBDX to use V2 endpoint - same as uploadBDXCoverHolder

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const inputEl = useRef(null);
  const methods = useForm({
    mode: 'onChange',
  });

  const [expanded, setExpanded] = useState([NO_MATCH, FUZZY]);

  const handleAccordion = (panel) => (_, isExpanded) => {
    if (!isExpanded) {
      const list = expanded.filter((l) => l !== panel);
      setExpanded(list);
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const handleCheckSanctions = () => {
    const values = methods.getValues();
    const sanctionFields = fields.filter(({ sanctionField }) => sanctionField).map(({ name }) => name);
    const sanctionFieldsEmptyFields = Object.fromEntries(
      Object.entries(values)
        .filter(([fieldName]) => sanctionFields.includes(fieldName))
        .filter(([_, fieldValue]) => !fieldValue)
    );

    if (Object.keys(sanctionFieldsEmptyFields).length) {
      const sanctionFieldsLabels = Object.keys(sanctionFieldsEmptyFields).map((name) => fields.find((field) => field.name === name)?.label);

      dispatch(
        showModal({
          component: 'CONFIRM',
          props: {
            title: utils.string.t('bdx.sanctionWarning'),
            subtitle: sanctionFieldsLabels.join(', '),
            fullWidth: true,
            maxWidth: 'xs',
            componentProps: {
              submitHandler: () => handleNext({ skipSanctions: true }),
              cancelLabel: utils.string.t('app.cancel'),
              confirmLabel: utils.string.t('app.continue'),
            },
          },
        })
      );

      return true;
    }

    return false;
  };

  const handleNext = async ({ skipSanctions }) => {
    if (!skipSanctions && handleCheckSanctions()) {
      return;
    }

    const isFormValid = await methods.trigger();
    if (isFormValid) {
      handlers.setLoading(true);
      const values = methods.getValues();
      setStoredMappingValues(values);
      try {
        const mappings = utils.uploadBdx.getMappingsfromValues(values, fields);

        if (mappings.length > 0) {
          const payload = {
            ...(isCoverHolderUpload
              ? {
                  ...validatedCoverHolderData,
                  expectedMapperFields: utils.uploadBdx.getMappingsfromValues(
                    values,
                    fields,
                    isCoverHolderUpload,
                    validatedCoverHolderData
                  ),
                  requestId,
                }
              : {
                  mappings: utils.uploadBdx.getMappingsfromValues(values, fields),
                  requestId,
                  rowNumber: rowId,
                }),
          };
          const { data, status } = await validateBdx(payload);

          const isSuccess = data.status === 'ACCEPTED' || status === 200;
          if (isSuccess) {
            handlers.getHandleCompleteStep(UPLOAD_BDX_STEPS.FIELD_MAPPING)();
            handlers.getHandleSetStep(UPLOAD_BDX_STEPS.SUMMARY)();
          }
        }
      } finally {
        handlers.setLoading(false);
      }
    }
  };

  const reUpload = async (rowNumber) => {
    handlers.setLoading(true);
    try {
      const { data } = await confirmBDXRow({ requestId, rowNumber: rowNumber - 1 });

      if (data) {
        setExpectedFields(data.expectedMapperFields);
        setSpreadsheetFields(data.spreadsheetFields);
        setRowId(data.rowId);
      }
    } finally {
      handlers.setLoading(false);
    }
  };

  const fields = useMemo(() => getMappingFormFields(), [getMappingFormFields]);
  const reqFields = fields.filter((field) => field.matchType === NO_MATCH);
  const fuzzyFields = fields.filter((field) => field.matchType === FUZZY);
  const previousFields = fields.filter((field) => field.matchType === PREVIOUS);
  const realFields = fields.filter((field) => field.matchType === REAL);

  useEffect(() => {
    fields?.forEach((field) => {
      methods.setValue(field.name, field.value);
    });

    if (Object.keys(storedMappingValues).length > 0) {
      Object.entries(storedMappingValues).forEach(([key, value]) => {
        methods.setValue(key, value);
      });
    }
  }, [fields, storedMappingValues, methods]);

  const matchedFields = Object.entries(methods.watch())
    .filter(([name]) => /field_\d+/.test(name))
    .map(([_, value]) => value?.mapperField)
    .filter(Boolean);

  const handleStep = () => handlers.getHandleSetStep(UPLOAD_BDX_STEPS.UPLOAD_FILE)();
  const handleMappingValues = () => setStoredMappingValues({});
  const handleConfirm = () => {
    handleStep();
    handleMappingValues();
  };

  const handleBackClick = useConfirmCancelModal(handleConfirm);

  return (
    <FormProvider {...methods}>
      <FieldMappingView
        rowId={rowId}
        expanded={expanded}
        handleAccordion={handleAccordion}
        fields={{ reqFields, fuzzyFields, realFields, matchedFields, previousFields }}
        methods={methods}
        isPopupOpen={isPopupOpen}
        inputEl={inputEl}
        sheetName={sheetName}
        handlers={{ ...handlers, handleNext, setIsPopupOpen, reUpload, handleBackClick }}
      />
    </FormProvider>
  );
}
