import * as React from 'react';
import { firstBy } from 'thenby';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

// MUI
import { Box, Table, TableRow, TableBody, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// app
import { Accordion, Pagination, TableCell, TableHead, Translate, MapBox, MapBoxTooltip, Overflow } from 'components';
import { LocationTooltip } from './LocationTooltip';
import { renderValue } from 'modules/QuoteBind/RiskData';
import { useSort } from 'hooks';
import * as utils from 'utils';
import { mapLayers } from 'utils/map/mapLayers';
import config from 'config';

const { pagination: paginationConfig } = config?.ui;

const useStyles = makeStyles(() => ({
  root: {
    color: 'red',
    '& .MuiTableCell-sizeSmall': {
      '&:first-child': {
        paddingLeft: 30,
      },

      '&:last-child': {
        paddingRight: 0,
      },
    },
  },
}));

export function RiskMap({ title, locations, locationDefinitions = [] }) {
  const classes = useStyles();
  const theme = useTheme();
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const [viewport, setViewport] = React.useState({});
  const [activeMarkers, setActiveMarkers] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    page: 0,
    rowsPerPage: paginationConfig.default,
    rowsPerPageOptions: paginationConfig.options,
    count: locations?.length,
  });

  const defaultSort = {
    by: 'id',
    direction: 'asc',
    type: 'numeric',
  };

  const columnItems =
    locationDefinitions?.arrayItemDef
      ?.filter((locationDefinition) => locationDefinition?.displayOnTable && locationDefinition?.type !== 'HIDDEN')
      ?.map((locationDefinition) => ({
        id: locationDefinition?.name,
        label: locationDefinition?.label,
        type: locationDefinition?.type,
        name: locationDefinition?.name,
        sort: {
          by: locationDefinition?.name,
          direction: 'asc',
          type: 'lexical',
        },
      })) || [];

  const columns = [
    { id: 'id', label: <Translate label="risks.building" />, sort: { by: 'id', type: 'numeric', direction: 'asc' } },
    ...columnItems,
    { id: 'buildingInfo', label: '' },
  ];

  const { cols, sort } = useSort(columns, defaultSort);
  const sortedLocations = locations.sort(firstBy(utils.sort.array(sort.type, sort.by, sort.direction)));

  const start = pagination.page * pagination.rowsPerPage;
  const end = start + pagination.rowsPerPage;

  const handleChangePage = (_, page) => {
    setPagination((prevState) => {
      return { ...prevState, page };
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPagination((prevState) => {
      return { ...prevState, rowsPerPage: event.target.value };
    });
  };

  const handleOpenTooltip = (open) => {
    setIsTooltipOpen(open);
  };

  const handleMarkerClick = ({ id }) => {
    const locationIndex = sortedLocations.findIndex((location) => location.id === id);

    setPagination((prevState) => {
      return { ...prevState, page: Math.floor(locationIndex / prevState.rowsPerPage) };
    });
    setActiveMarkers([id]);
  };

  const handleRowClick = (location) => {
    setViewport({
      ...viewport,
      center: [location.longitude, location.latitude],
      zoom: config.mapbox.marker.maxZoom,
    });

    setActiveMarkers([location.id]);
  };

  const customTooltipComponent = ({ tooltip }) => {
    const title = `${tooltip.streetAddress}, ${tooltip.zip}, ${tooltip.city}, ${tooltip.county}, ${tooltip.state}`;

    return tooltip.buildingTitle ? <MapBoxTooltip title={tooltip.buildingTitle} list={[{ icon: <LocationOnIcon />, title }]} /> : null;
  };

  return (
    <Box
      style={{
        marginTop: '20px',
      }}
    >
      <Accordion
        type="solid"
        bgColor={theme.palette.grey[100]}
        icon={<ExpandMoreIcon />}
        iconPosition="right"
        titleComponent={
          <Typography variant="h3" style={{ marginBottom: 0, marginRight: 10, fontWeight: 600, flex: 1 }}>
            {startCase(toLower(title))}
          </Typography>
        }
        testid="risk-map"
      >
        <Box
          style={{
            height: '400px',
            width: '100%',
          }}
        >
          <MapBox
            data-testid="mapbox"
            id="markers"
            height={400}
            activeMarkers={activeMarkers}
            onClickMarker={handleMarkerClick}
            fitBounds
            locations={sortedLocations}
            allowFullscreen
            allowSatelliteView
            allowScrollZoom
            allowMarkerClickZoom
            layers={mapLayers}
            tooltipComponent={customTooltipComponent}
            {...viewport}
          />
        </Box>
        <Box>
          <Overflow>
            <Table size="small" classes={{ root: classes.root }}>
              <TableHead
                columns={cols}
                sorting={{ by: sort.by, direction: sort.direction }}
                nestedClasses={{ tableHead: classes.tableHead }}
              />
              <TableBody>
                {sortedLocations.slice(start, end).map((location) => {
                  return (
                    <TableRow
                      key={location.id}
                      data-testid={`location-row`}
                      hover
                      selected={activeMarkers.includes(location.id)}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(location)}
                    >
                      {columns.map((column) => {
                        const value = location[column.id];

                        return (
                          <TableCell key={column.id} align="left" className={classes.tableCell}>
                            {column.id === 'buildingInfo' ? (
                              <LocationTooltip
                                location={location}
                                locationDefinitions={locationDefinitions}
                                excludeColumns={columns}
                                handleOpen={handleOpenTooltip}
                                disabled={isTooltipOpen}
                              />
                            ) : column.id === 'id' ? (
                              location.buildingTitle
                            ) : (
                              <Typography variant="body2">{renderValue(column, value)}</Typography>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              page={pagination.page}
              count={pagination.count}
              rowsPerPage={pagination.rowsPerPage}
              rowsPerPageOptions={pagination.rowsPerPageOptions}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Overflow>
        </Box>
      </Accordion>
    </Box>
  );
}
